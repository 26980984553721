<script setup>
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';

// Layouts
import PublicLayout from '@/Layouts/PublicLayout.vue';

// Components
import Button from '@/Components/Button/Button.vue';
import Content from '@/Components/Content.vue';

const { t } = useI18n();
const route = inject('route');
</script>

<template>
    <PublicLayout :title="t('404 - Page not found')">
        <div class="container my-12">
            <Content
                :title="t('404 - Page not found ')"
                :text="
                    t(
                        'We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved. '
                    )
                "
            />

            <Button
                :is="'a'"
                :href="route('public.pages.index')"
                :label="t('Back to home')"
                class="mt-10"
            />
        </div>
    </PublicLayout>
</template>
